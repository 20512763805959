import React from 'react';
import { Link } from 'gatsby';
import * as PropTypes from 'prop-types';

import * as styles from './portfolioCompanies.module.scss';

const PortfolioCompanies = ({ companies }) => {
  return (
    <div className={styles.wrapper}>
      <h2 className={styles.heading}>Portfolio companies</h2>
      <div className={styles.content}>
        {companies.map((el, id) => {
          return (
            <div className={styles.companyWrapper} key={id}>
              <div className={styles.logoWrapper}>
                {el.company && (
                  <Link to={`/portfolio/${el.company.slug}`} className={styles.company}>
                    {el.logoGray ? (
                      <img
                        className={styles.imgWrapper}
                        src={el.logoGray.fluid ? el.logoGray.fluid.src : ''}
                        alt={el.logoGray.title}
                      />
                    ) : (
                      <div className={styles.imgWrapper}>
                        <span className={styles.companyName}>{el.company.name}</span>
                      </div>
                    )}
                  </Link>
                )}
              </div>
              {el.shortDescription && (
                <p className={styles.description}>{el.shortDescription.shortDescription}</p>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

PortfolioCompanies.propTypes = {
  companies: PropTypes.array,
};

export default PortfolioCompanies;

import React, { useState } from 'react';
import Slider from 'react-slick';

import ChevronLeft from '../../../../svgs/ChevronLeft';
import ChevronRight from '../../../../svgs/ChevronRight';

import * as styles from './convictionPageSlider.module.scss';
import '../../../../styles/vendor/slick-conviction-page-slider.scss';

const ConvictionPageSlider = ({ title, children }) => {
  const [activeSlide, setActiveSlide] = useState(1);

  const slider = React.useRef(null);

  const createSlickSettings = () => {
    return {
      beforeChange: (current, next) => setActiveSlide(next + 1),
      infinite: false,
      arrows: false,
      speed: 500,
      variableWidth: false,
      slidesToScroll: 3,
      slidesToShow: 3,
      responsive: [
        {
          breakpoint: 1070,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 770,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
  };

  const slickSettings = createSlickSettings();

  return (
    <div className={styles.wrapper}>
      <div className={styles.heading}>
        <h2 className={styles.slideTitle}>{title}</h2>
        <div className={styles.buttonsWrapper}>
          <button className={styles.button} onClick={() => slider?.current?.slickPrev()}>
            <ChevronLeft className={styles.chevron} />
          </button>
          <button className={styles.button} onClick={() => slider?.current?.slickNext()}>
            <ChevronRight className={styles.chevron} />
          </button>
        </div>
      </div>

      <div className={['slick-conviction-area-slider']}>
        <Slider ref={slider} {...slickSettings}>
          {children}
        </Slider>
      </div>
    </div>
  );
};

export default ConvictionPageSlider;

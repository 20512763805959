import React from 'react';
import * as PropTypes from 'prop-types';

import * as styles from './quote.module.scss';

const Quote = ({ quote, name, title, linkTitle, linkUrl, imageSrc, imageAlt }) => {
  return (
    quote && (
      <div>
        <h2 className={styles.quote}>{quote}</h2>
        <div className={styles.details}>
          {imageSrc && (
            <img className={styles.image} src={imageSrc} alt={imageAlt ? imageAlt : ''} />
          )}
          <div>
            <div>{name && <span className={styles.authorName}>{name}</span>}</div>
            <div className={styles.additionalData}>
              {title && <span className={styles.title}>{title}</span>}
              {linkUrl && (
                <a href={linkUrl} className={styles.cta}>
                  {linkTitle}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

Quote.propTypes = {
  quote: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  linkTitle: PropTypes.string,
  linkUrl: PropTypes.string,
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
};

export default Quote;

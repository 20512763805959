import React from 'react';
import { graphql } from 'gatsby';

import GeneralLayout from '../../layouts/index';
import Seo from '../../components/SEO';
import Theme from '../../../src/components/Theme';
import Newsletter from '../../components/Newsletter';
import HeadingSection from '../../components/ConvictionPage/HeadingSection';
import MainSection from '../../components/ConvictionPage/MainSection';

const ConvictionPageTemplate = ({ transition, data }) => {
  const { contentfulConvictionPage, contentfulConvictionAreas, contentfulNewsletter, site } = data;
  return (
    <GeneralLayout>
      <Theme theme="emerald" transition={transition}>
        <Seo
          siteData={site}
          seoData={contentfulConvictionAreas.seo}
          defaultTitle="Conviction Area"
          pathName={`/conviction-areas/${contentfulConvictionPage.slug}`}
        />
        <HeadingSection content={contentfulConvictionPage} />
        <MainSection content={contentfulConvictionPage} />
        <Newsletter content={contentfulNewsletter} />
      </Theme>
    </GeneralLayout>
  );
};

export default ConvictionPageTemplate;
export const pageQuery = graphql`
  query convictionPageQuery($id: String) {
    contentfulConvictionPage(id: { eq: $id }) {
      image {
        fluid {
          src
        }
        title
      }
      additionalContext {
        additionalContext
      }
      slug
      id
      blogLinkTitle
      blogLinkUrl
      description {
        description
      }
      thoughts {
        slug
        title
        publishedDate(formatString: "MM.DD.YYYY")
        tag {
          slug
          name
        }
        author {
          name
        }
        secondaryAuthor {
          name
        }
      }
      optionalDisclaimer {
        optionalDisclaimer
      }
      portfolioCompanies {
        shortDescription {
          shortDescription
        }
        company {
          slug
          name
        }
        logoGray {
          fluid {
            src
          }
          title
        }
      }
      press {
        title
        publishedDate(formatString: "MM.DD.YYYY")
        url
        publication
      }
      quote {
        quote
      }
      quoteAuthorName
      quoteTitle
      quoteCompanyImg {
        fluid {
          src
        }
        title
      }
      subheadline
      title
    }
    contentfulConvictionAreas {
      ...MainConvictionAreasQuery
      ...MinorConvictionAreasQuery
      seo {
        customTitle
      }
    }
    ...NewsletterQuery
    ...SiteQuery
  }
`;

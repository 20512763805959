import React from 'react';

const chevronLeft = ({ className }) => (
  <svg
    className={['fill-current', className].join(' ')}
    xmlns="http://www.w3.org/2000/svg"
    width="17.4"
    height="17.4"
    viewBox="0 0 17.4 17.4">
    <polygon points="12.4,17.4 3.6,8.7 12.4,0 13.8,1.4 6.5,8.7 13.8,16 	" />
  </svg>
);

export default chevronLeft;

import React from 'react';
import * as PropTypes from 'prop-types';

import * as styles from './subheadline.module.scss';

const Subheadline = ({ subheadline, additionalContext, optionalDisclaimer }) => {
  return (
    <div className={styles.subheadlineWrapper}>
      <h2 className={styles.subheadlineTitle}>{subheadline}</h2>
      <div className={styles.descriptionsWrapper}>
        <p className={styles.subheadlineDescription}>{additionalContext}</p>
        <p className={[styles.subheadlineDescription, styles.additionalDescription].join(' ')}>
          {optionalDisclaimer}
        </p>
      </div>
    </div>
  );
};

Subheadline.propTypes = {
  subheadline: PropTypes.string,
  additionalContext: PropTypes.string,
  optionalDisclaimer: PropTypes.string,
};

export default Subheadline;

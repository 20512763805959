import React from 'react';
import * as PropTypes from 'prop-types';

import Subheadline from './Subheadline';
import ConvictionPageSlider from './ConvictionPageSlider';
import SliderTile from './SliderTile';
import Quote from './Quote';
import PortfolioCompanies from './PortfolioCompanies';

import * as styles from './mainSection.module.scss';

const MainSection = ({ content }) => {
  return (
    <section className={styles.mainSection}>
      <div className={styles.content}>
        {content.subheadline && (
          <Subheadline
            subheadline={content.subheadline}
            additionalContext={
              content.additionalContext ? content.additionalContext.additionalContext : ''
            }
            optionalDisclaimer={
              content.optionalDisclaimer ? content.optionalDisclaimer.optionalDisclaimer : ''
            }
          />
        )}

        {content.thoughts && (
          <ConvictionPageSlider title="thoughts">
            {content.thoughts.map(el => {
              return (
                <SliderTile
                  key={el.title}
                  title={el.title}
                  thoughtsUrl={el.slug}
                  authorName={el.author ? el.author.name : ''}
                  secondaryAuthorName={el.secondaryAuthor ? el.secondaryAuthor.name : ''}
                  date={el.publishedDate}
                  tags={el.tag}
                />
              );
            })}
          </ConvictionPageSlider>
        )}

        {content.quote && (
          <Quote
            quote={content.quote ? content.quote.quote : ''}
            name={content.quoteAuthorName ? content.quoteAuthorName : ''}
            title={content.quoteTitle ? content.quoteTitle : ''}
            linkTitle={content.quoteLinkTitle ? content.quoteLinkTitle : ''}
            linkUrl={content.quoteLinkUrl ? content.quoteLinkUrl : ''}
            imageSrc={content.quoteCompanyImg ? content.quoteCompanyImg.fluid.src : ''}
            imageAlt={content.quoteCompanyImg ? content.quoteCompanyImg.title : ''}
          />
        )}
        {content.portfolioCompanies && (
          <PortfolioCompanies companies={content.portfolioCompanies} />
        )}
        {content.press && (
          <ConvictionPageSlider title="press">
            {content.press.map(el => {
              return (
                <SliderTile
                  key={el.title}
                  title={el.title}
                  name={el.publication}
                  date={el.publishedDate}
                  pressUrl={el.url}
                />
              );
            })}
          </ConvictionPageSlider>
        )}
      </div>
    </section>
  );
};

MainSection.propTypes = {
  content: PropTypes.object.isRequired,
};

export default MainSection;

import React from 'react';
import { Link } from 'gatsby';
import * as PropTypes from 'prop-types';

import * as styles from './sliderTile.module.scss';
import Tags from '../../../Tags';

const sliderTile = ({
  title,
  thoughtsUrl,
  pressUrl,
  authorName,
  secondaryAuthorName,
  logo,
  date,
  tags,
}) => {
  return (
    <div className={styles.wrapper}>
      {thoughtsUrl && (
        <Link to={`/thoughts/${thoughtsUrl}`} className={styles.titleLink}>
          <h3 className={styles.title}>{title}</h3>
        </Link>
      )}
      {pressUrl && (
        <a href={pressUrl} className={styles.titleLink}>
          <h3 className={styles.title}>{title}</h3>
        </a>
      )}
      <div>
        <div className={styles.author}>
          {authorName && <span className={styles.authorName}>{authorName}</span>}
          {secondaryAuthorName && (
            <span className={styles.authorName}>& {secondaryAuthorName}</span>
          )}

          {logo && <div>{logo}</div>}
        </div>
        <div className={styles.additionalData}>
          <span className={styles.date}>{date}</span>
          <div className={styles.tagsWrapper}>{tags && <Tags tags={tags} styleMeta={true} />}</div>
        </div>
      </div>
    </div>
  );
};

sliderTile.propTypes = {
  title: PropTypes.string,
  thoughtsUrl: PropTypes.string,
  pressUrl: PropTypes.string,
  authorName: PropTypes.string,
  secondaryAuthorName: PropTypes.string,
  logo: PropTypes.string,
  date: PropTypes.string,
  tags: PropTypes.array,
};

export default sliderTile;

import React from 'react';
import * as PropTypes from 'prop-types';

import * as styles from './headingSection.module.scss';

const HeadingSection = ({ content }) => {
  return (
    <section className={styles.headingSection}>
      <div className={styles.wrapper}>
        <div className={styles.imageWraper}>
          {content.image && (
            <img className={styles.image} src={content.image.fluid.src} alt={content.image.title} />
          )}
        </div>
        <div className={styles.content}>
          <h2 className={styles.heading}>Conviction Area</h2>
          {content.title && <h1 className={styles.title}>{content.title}</h1>}
          {content.description && (
            <p className={styles.description}>{content.description.description}</p>
          )}
          {content.blogLinkUrl && (
            <a href={content.blogLinkUrl} className={styles.link}>
              {content.blogLinkTitle}
            </a>
          )}
        </div>
      </div>
    </section>
  );
};

HeadingSection.propTypes = {
  content: PropTypes.object.isRequired,
};

export default HeadingSection;

import React from 'react';

const chevronRight = ({ className }) => (
  <svg
    className={['fill-current', className].join(' ')}
    xmlns="http://www.w3.org/2000/svg"
    width="17.4"
    height="17.4"
    viewBox="0 0 17.4 17.4">
    <polygon points="5.1,17.4 13.8,8.7 5.1,0 3.6,1.4 10.9,8.7 3.6,16 	" />
  </svg>
);

export default chevronRight;
